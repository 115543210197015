.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.pageLayoutComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___6VWx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 600px) {
  .freeBox___6VWx {
    padding: 0px;
  }
}
._002Header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--token-D1V5iINMo);
  width: 100%;
  min-width: 0;
}
@media (min-width: 900px) {
  .background {
    background: url("/plasmic/placeit/images/backgroundPng2.png") top 0% right 0% / 50% auto no-repeat,
      var(--token-D1V5iINMo);
  }
}
@media (min-width: 1024px) {
  .background {
    background: url("/plasmic/placeit/images/backgroundPng2.png") top 0% right 0% / auto 70% no-repeat,
      var(--token-D1V5iINMo);
  }
}
.headerContentContainer:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.text__lQKnW {
  font-size: var(--token-WkKdYF02Z);
  text-align: left;
  width: 100%;
  min-width: 0;
}
.freeBox__u2UaC {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-JIfrimaWtNy);
  padding-bottom: var(--token-JIfrimaWtNy);
}
.freeBox__u2UaC > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox__u2UaC > :global(.__wab_flex-container) > *,
.freeBox__u2UaC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u2UaC > :global(.__wab_flex-container) > picture > img,
.freeBox__u2UaC
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 600px) {
  .freeBox__u2UaC {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .freeBox__u2UaC > :global(.__wab_flex-container) {
    align-items: stretch;
    min-width: 0;
  }
}
.freeBox__aNslf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__aNslf > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-u8uQ-APga));
  height: calc(100% + var(--token-u8uQ-APga));
}
.freeBox__aNslf > :global(.__wab_flex-container) > *,
.freeBox__aNslf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aNslf > :global(.__wab_flex-container) > picture > img,
.freeBox__aNslf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-u8uQ-APga);
}
@media (min-width: 600px) {
  .freeBox__aNslf {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .freeBox__aNslf > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - var(--token-u8uQ-APga));
    width: calc(100% + var(--token-u8uQ-APga));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .freeBox__aNslf > :global(.__wab_flex-container) > *,
  .freeBox__aNslf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__aNslf > :global(.__wab_flex-container) > picture > img,
  .freeBox__aNslf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-u8uQ-APga);
    margin-top: 0px;
  }
}
.link__fpRG {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  color: var(--token-TDUv9IlsPLe);
  max-width: 240px;
  max-height: 70px;
}
@media (min-width: 600px) {
  .link__fpRG {
    width: auto;
  }
}
.img__snZw {
  object-fit: contain;
  height: 100%;
  max-height: 70px;
  min-height: 0;
}
.img__snZw > picture > img {
  object-fit: contain;
}
.link__qTbTx {
  display: flex;
  color: var(--token-TDUv9IlsPLe);
  max-width: 240px;
  flex-direction: column;
  max-height: 70px;
  align-items: flex-start;
  justify-content: flex-start;
}
.img__sk2Tu {
  object-fit: contain;
  align-self: center;
  object-position: left 50%;
  height: 100%;
  max-height: 70px;
  min-height: 0;
}
.img__sk2Tu > picture > img {
  object-fit: contain;
  object-position: left 50%;
}
.img__qDjaj {
  object-fit: contain;
  max-width: 100%;
  max-height: 104px;
  object-position: left 50%;
}
.img__qDjaj > picture > img {
  object-fit: contain;
  object-position: left 50%;
}
@media (min-width: 600px) {
  .img__qDjaj {
    height: 144px;
    flex-shrink: 0;
  }
}
.img__lxhn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  object-position: left bottom;
  border-radius: 15px;
}
.img__lxhn > picture > img {
  object-fit: cover;
  object-position: left bottom;
}
@media (min-width: 1536px) {
  .img__lxhn {
    max-height: 650px;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  margin-top: 0;
  display: none;
}
@media (min-width: 600px) {
  .button:global(.__wab_instance) {
    display: none;
  }
}
.text__fHuhq {
  color: var(--token-TDUv9IlsPLe);
  font-size: var(--token-OVQ_pKinX);
  text-align: left;
}
@media (min-width: 1280px) {
  .text__fHuhq {
    font-size: var(--token-7vjXi0uPm);
  }
}
.text__tdWtT {
  color: var(--token-aLKgcASGV);
  font-size: var(--token-wSGdpGsDD);
  text-align: left;
}
@media (min-width: 1280px) {
  .text__tdWtT {
    font-size: var(--token-hOqCuytDszPG);
  }
}
.svg__xRjfU {
  width: 110px;
  height: 26px;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-8j5e4W9rawI);
}
.svg__uHhC {
  width: 216px;
  height: 44px;
  align-self: auto;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-TDUv9IlsPLe);
}
.content {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-eX3zVaWtY));
  height: calc(100% + var(--token-eX3zVaWtY));
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-eX3zVaWtY);
}
@media (min-width: 900px) {
  .content > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-IXhP74seA));
    height: calc(100% + var(--token-IXhP74seA));
  }
}
@media (min-width: 900px) {
  .content > :global(.__wab_flex-container) > *,
  .content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .content > :global(.__wab_flex-container) > picture > img,
  .content
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-IXhP74seA);
  }
}
@media (min-width: 1024px) {
  .content {
    padding-left: var(--token-cTiaoKBgu);
    padding-right: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1900px) {
  .content {
    width: 80%;
  }
}
.appOverview {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .appOverview {
    padding-bottom: var(--token-JIfrimaWtNy);
  }
}
@media (min-width: 900px) {
  .appOverview {
    width: 100%;
    min-width: 0;
  }
}
.columns__cPWmM {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__cPWmM > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__cPWmM > :global(.__wab_flex-container) > *,
.columns__cPWmM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__cPWmM > :global(.__wab_flex-container) > picture > img,
.columns__cPWmM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__cPWmM > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__cPWmM {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__cPWmM > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__cPWmM > :global(.__wab_flex-container) > *,
  .columns__cPWmM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__cPWmM > :global(.__wab_flex-container) > picture > img,
  .columns__cPWmM
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__cPWmM > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__cPWmM > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__cPWmM {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__cPWmM > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__cPWmM > :global(.__wab_flex-container) > *,
  .columns__cPWmM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__cPWmM > :global(.__wab_flex-container) > picture > img,
  .columns__cPWmM
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column___3TA0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column___3TA0 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column___3TA0 > :global(.__wab_flex-container) > *,
.column___3TA0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___3TA0 > :global(.__wab_flex-container) > picture > img,
.column___3TA0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .column___3TA0 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 900px) {
  .column___3TA0 {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .column___3TA0 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
.heading__rneM:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  text-align: center;
  color: var(--token-TDUv9IlsPLe);
}
@media (min-width: 600px) {
  .heading__rneM:global(.__wab_instance) {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .heading__rneM:global(.__wab_instance) {
    font-size: 40px;
    height: 64px;
    flex-shrink: 0;
  }
}
.heading__tAoN3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  color: #535353;
  text-align: left;
}
@media (min-width: 600px) {
  .heading__tAoN3:global(.__wab_instance) {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .heading__tAoN3:global(.__wab_instance) {
    font-size: 32px;
  }
}
.text__fouga {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  padding-top: 0px;
  text-align: left;
  min-width: 0;
}
@media (min-width: 600px) {
  .text__fouga {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .text__fouga {
    text-align: left;
  }
}
.userConditionalStack:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  padding: 0px;
}
.ssoTrigger:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__zKel6 {
  width: 100%;
  height: auto;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  min-width: 0;
}
.column___4BF39 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column___4BF39 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.column___4BF39 > :global(.__wab_flex-container) > *,
.column___4BF39 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___4BF39 > :global(.__wab_flex-container) > picture > img,
.column___4BF39
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 900px) {
  .column___4BF39 {
    padding: 0px;
  }
}
.freeBox__eSm0H {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__eSm0H > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox__eSm0H > :global(.__wab_flex-container) > *,
.freeBox__eSm0H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eSm0H > :global(.__wab_flex-container) > picture > img,
.freeBox__eSm0H
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img__sSmv {
  object-fit: cover;
  max-width: 100%;
}
.img__sSmv > picture > img {
  object-fit: cover;
}
.text__qC4LA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-left: 0px;
  font-size: 18px;
  min-width: 0;
}
.freeBox___2ZxF9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox___2ZxF9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox___2ZxF9 > :global(.__wab_flex-container) > *,
.freeBox___2ZxF9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2ZxF9 > :global(.__wab_flex-container) > picture > img,
.freeBox___2ZxF9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img___6FbKh {
  object-fit: cover;
  max-width: 100%;
}
.img___6FbKh > picture > img {
  object-fit: cover;
}
.text__fH65A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-left: 0px;
  font-size: 18px;
  min-width: 0;
}
.freeBox___9HbBo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox___9HbBo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox___9HbBo > :global(.__wab_flex-container) > *,
.freeBox___9HbBo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9HbBo > :global(.__wab_flex-container) > picture > img,
.freeBox___9HbBo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img__gbGse {
  object-fit: cover;
  max-width: 100%;
}
.img__gbGse > picture > img {
  object-fit: cover;
}
.text__f0NXy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  padding-left: 0px;
  min-width: 0;
}
.mockupsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .mockupsSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns___19QSk {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns___19QSk > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns___19QSk > :global(.__wab_flex-container) > *,
.columns___19QSk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___19QSk > :global(.__wab_flex-container) > picture > img,
.columns___19QSk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns___19QSk > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns___19QSk {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns___19QSk > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns___19QSk > :global(.__wab_flex-container) > *,
  .columns___19QSk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___19QSk > :global(.__wab_flex-container) > picture > img,
  .columns___19QSk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns___19QSk > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns___19QSk > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns___19QSk > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns___19QSk > :global(.__wab_flex-container) > *,
  .columns___19QSk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___19QSk > :global(.__wab_flex-container) > picture > img,
  .columns___19QSk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__gPnVf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__gPnVf > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__gPnVf > :global(.__wab_flex-container) > *,
.column__gPnVf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__gPnVf > :global(.__wab_flex-container) > picture > img,
.column__gPnVf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .column__gPnVf > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
.img__dB9P {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__dB9P > picture > img {
  object-fit: cover;
}
.column__fwqcs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__fwqcs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__fwqcs > :global(.__wab_flex-container) > *,
.column__fwqcs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__fwqcs > :global(.__wab_flex-container) > picture > img,
.column__fwqcs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__zeTd6:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  text-align: left;
}
@media (min-width: 900px) {
  .heading__zeTd6:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__rEZzm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 563px;
  font-size: 18px;
  text-align: left;
  min-width: 0;
  padding: 0px;
}
.text__gBh6 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.designsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .designsSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns__oL9Md {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__oL9Md > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__oL9Md > :global(.__wab_flex-container) > *,
.columns__oL9Md > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__oL9Md > :global(.__wab_flex-container) > picture > img,
.columns__oL9Md
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__oL9Md > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__oL9Md {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__oL9Md > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__oL9Md > :global(.__wab_flex-container) > *,
  .columns__oL9Md > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__oL9Md > :global(.__wab_flex-container) > picture > img,
  .columns__oL9Md
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__oL9Md > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__oL9Md > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__oL9Md {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__oL9Md > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__oL9Md > :global(.__wab_flex-container) > *,
  .columns__oL9Md > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__oL9Md > :global(.__wab_flex-container) > picture > img,
  .columns__oL9Md
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__caEe9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__caEe9 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__caEe9 > :global(.__wab_flex-container) > *,
.column__caEe9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__caEe9 > :global(.__wab_flex-container) > picture > img,
.column__caEe9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__jHbj3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  margin-right: 0px;
  width: 557px;
}
@media (min-width: 900px) {
  .heading__jHbj3:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__mjron {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 591px;
  font-size: 18px;
  min-width: 0;
  padding: 0px;
}
.text__tvSNk {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.column__rRc41 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column__rRc41 {
    justify-content: center;
    align-items: center;
  }
}
.img__iAcMb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__iAcMb > picture > img {
  object-fit: cover;
}
.logoSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .logoSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns__q6SuS {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__q6SuS > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__q6SuS > :global(.__wab_flex-container) > *,
.columns__q6SuS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__q6SuS > :global(.__wab_flex-container) > picture > img,
.columns__q6SuS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__q6SuS > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__q6SuS {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__q6SuS > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__q6SuS > :global(.__wab_flex-container) > *,
  .columns__q6SuS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__q6SuS > :global(.__wab_flex-container) > picture > img,
  .columns__q6SuS
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__q6SuS > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__q6SuS > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__q6SuS {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__q6SuS > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__q6SuS > :global(.__wab_flex-container) > *,
  .columns__q6SuS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__q6SuS > :global(.__wab_flex-container) > picture > img,
  .columns__q6SuS
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__wbtPg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column__wbtPg {
    justify-content: center;
    align-items: center;
  }
}
.img__twSf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__twSf > picture > img {
  object-fit: cover;
}
.column__tdL6G {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__tdL6G > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__tdL6G > :global(.__wab_flex-container) > *,
.column__tdL6G > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__tdL6G > :global(.__wab_flex-container) > picture > img,
.column__tdL6G
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading___8JS5L:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 900px) {
  .heading___8JS5L:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__yeERh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 626px;
  font-size: 18px;
  padding-top: 0px;
  min-width: 0;
}
.text__ocn0U {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 20px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.videosSection {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 40px;
}
.videosSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-eX3zVaWtY));
  height: calc(100% + var(--token-eX3zVaWtY));
}
.videosSection > :global(.__wab_flex-container) > *,
.videosSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.videosSection > :global(.__wab_flex-container) > picture > img,
.videosSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-eX3zVaWtY);
}
@media (min-width: 900px) {
  .videosSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .videosSection > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.columns___8ZTxC {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns___8ZTxC > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns___8ZTxC > :global(.__wab_flex-container) > *,
.columns___8ZTxC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___8ZTxC > :global(.__wab_flex-container) > picture > img,
.columns___8ZTxC
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns___8ZTxC > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns___8ZTxC {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns___8ZTxC > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns___8ZTxC > :global(.__wab_flex-container) > *,
  .columns___8ZTxC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___8ZTxC > :global(.__wab_flex-container) > picture > img,
  .columns___8ZTxC
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns___8ZTxC > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns___8ZTxC > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns___8ZTxC {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns___8ZTxC > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns___8ZTxC > :global(.__wab_flex-container) > *,
  .columns___8ZTxC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___8ZTxC > :global(.__wab_flex-container) > picture > img,
  .columns___8ZTxC
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column___42HSk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column___42HSk > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column___42HSk > :global(.__wab_flex-container) > *,
.column___42HSk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___42HSk > :global(.__wab_flex-container) > picture > img,
.column___42HSk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__c8JMf:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  width: 641px;
}
@media (min-width: 900px) {
  .heading__c8JMf:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__wrp5E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 582px;
  font-size: 18px;
  padding-top: 0px;
  min-width: 0;
}
.text__vMDt6 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 20px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.column__s8YyF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column__s8YyF {
    justify-content: center;
    align-items: center;
  }
}
.img__m6GDm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__m6GDm > picture > img {
  object-fit: cover;
}
.buttonsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  min-width: 0;
  padding: var(--token-omGhEM8OI) 0px 0px;
}
.buttonsSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.buttonsSection > :global(.__wab_flex-container) > *,
.buttonsSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsSection > :global(.__wab_flex-container) > picture > img,
.buttonsSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 600px) {
  .buttonsSection {
    max-width: 1080px;
  }
}
.heading__aXldV:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: 32px;
  color: #860ee6;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
@media (min-width: 900px) {
  .heading__aXldV:global(.__wab_instance) {
    height: 60px;
    flex-shrink: 0;
  }
}
.freeBox__jvzfh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__jvzfh > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.freeBox__jvzfh > :global(.__wab_flex-container) > *,
.freeBox__jvzfh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jvzfh > :global(.__wab_flex-container) > picture > img,
.freeBox__jvzfh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .freeBox__jvzfh {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .freeBox__jvzfh > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: calc(0px - var(--token-TCakqYrfy));
    width: calc(100% + var(--token-TCakqYrfy));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .freeBox__jvzfh > :global(.__wab_flex-container) > *,
  .freeBox__jvzfh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__jvzfh > :global(.__wab_flex-container) > picture > img,
  .freeBox__jvzfh
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-TCakqYrfy);
    margin-top: 0px;
  }
}
.link__qDgmm {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  max-width: 237px;
  max-height: 69px;
  min-width: 0;
}
@media (min-width: 600px) {
  .link__qDgmm {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.img__nG2Ke {
  object-fit: contain;
  max-width: 100%;
  max-height: 69px;
}
.img__nG2Ke > picture > img {
  object-fit: contain;
}
.link__td44S {
  position: relative;
  display: flex;
  width: auto;
  height: auto;
  max-width: 208px;
  max-height: 69px;
}
@media (min-width: 600px) {
  .link__td44S {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.img__gXVn1 {
  object-fit: contain;
  max-width: 100%;
  max-height: 69px;
}
.img__gXVn1 > picture > img {
  object-fit: contain;
}
.freeBox___0R96G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 104px;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .freeBox___0R96G {
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 80px;
    padding: 0px;
  }
}
.img__j7IiD {
  object-fit: contain;
  max-width: 100%;
  max-height: 104px;
}
.img__j7IiD > picture > img {
  object-fit: contain;
}
.text__yuC6W {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 16px;
  text-align: center;
  min-width: 0;
  padding: 0px 0px 40px;
}
.link__dHnj {
  color: #0070f3;
  padding-top: 0px;
  padding-bottom: 0px;
}
._017CtaBlock:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.seoTag:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
